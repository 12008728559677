import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import { Container, Row, Col, useScreenClass, Hidden } from "react-grid-system"
import styled from "styled-components"
import BGImage from "../images/cx-bg-image.png"

import SEO from "../components/seo"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Main from "../components/main"

const StyledMain = styled(Main)`
  padding-top: 9em;
  background: center / cover no-repeat url(${BGImage});
  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobile}) {
    padding-top: 2em;
  }
`

const InnerCol = styled(Col)`
  max-width: 596px !important;
  @media only screen and (max-width: 1366px) {
    max-width: 100% !important;
  }
`

const Headline = styled.h1`
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.fonts.serif};
  font-size: 2.5rem;
  line-height: 1;
  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobile}) {
    font-size: 1.78rem;
    margin-top: 80px;
  }
`

const IframeWrapper = styled.div`
  overflow: hidden;
  position: relative;
  padding-top: 56.25%;
  iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.tablet}) {
    height: 650px;
  }
`

const CXPage = (props) => {
  const { seoTitle, seoDescription, seoHelmet, openGraphImage } =
    props.data.contentfulPage
  const screenClass = useScreenClass()
  const iframeHtml = `<iframe id="JotFormIFrame-201044944064045" title="Customer Experience Assessment" onload="window.parent.scrollTo(0,0)" allowtransparency="true" allowfullscreen="true" src="https://form.jotform.com/201044944064045?nojump" frameborder="0" style=" min-width: 100%; min-height:100%; border:none;" scrolling="yes" > </iframe>`
  const iframe = () => {
    return {
      __html: iframeHtml,
    }
  }
  return (
    <>
      <SEO
        title={seoTitle ? seoTitle : ``}
        description={
          seoDescription && seoDescription.seoDescription
            ? seoDescription.seoDescription
            : ``
        }
        pageUrl={props?.location?.href}
        image={openGraphImage?.localFile?.publicURL}
      />
      <Layout>
        <StyledMain>
          <Container
            style={{
              paddingLeft: ["md", "lg", "xl"].includes(screenClass)
                ? "3.4rem"
                : "33px",
              paddingRight: ["md", "lg", "xl"].includes(screenClass)
                ? "3.4rem"
                : "33px",
            }}
          >
            <Row>
              <Col lg={6}>
                <Row justify="end">
                  <InnerCol
                    style={{
                      paddingLeft: ["md", "lg", "xl"].includes(screenClass)
                        ? "3.4rem"
                        : "33px",
                      paddingRight: ["md", "lg", "xl"].includes(screenClass)
                        ? ".4rem"
                        : "33px",
                    }}
                  >
                    <Headline
                      dangerouslySetInnerHTML={{
                        __html: props.data.contentfulPage.mainHeadline,
                      }}
                      className={`headline`}
                    />
                  </InnerCol>
                </Row>
              </Col>
              <Col>
                <IframeWrapper dangerouslySetInnerHTML={iframe()} />
              </Col>
            </Row>
          </Container>
        </StyledMain>
      </Layout>
    </>
  )
}

export const query = graphql`
  query CustomerExperienceAssessmentQuery {
    contentfulPage(slug: { eq: "customer-experience-assessment" }) {
      id
      slug
      name
      mainHeadline
      seoTitle
      seoDescription {
        seoDescription
      }
      seoHelmet {
        seoHelmet
      }
      openGraphImage {
        localFile {
          publicURL
        }
      }
    }
  }
`

export default CXPage
